import * as THREE from "three";
import React, { Suspense, useCallback, useRef, useMemo } from "react";
import { Canvas, useFrame, useThree } from "react-three-fiber";
import { Stars, TrackballControls, Text } from "drei";
import { Controls, useControl } from "react-three-gui";

import Effects from "./Effects";

import "./styles.css";

function Swarm({ count, mouse }) {
  const mesh = useRef();
  const dummy = useMemo(() => new THREE.Object3D(), []);

  const particles = useMemo(() => {
    const temp = [];
    for (let i = 0; i < count; i++) {
      const t = Math.random() * 100;
      const factor = 20 + Math.random() * 100;
      const speed = 0.01 + Math.random() / 200;
      const xFactor = -20 + Math.random() * 40;
      const yFactor = -20 + Math.random() * 40;
      const zFactor = -20 + Math.random() * 40;
      temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 });
    }
    return temp;
  }, [count]);

  useFrame((state) => {
    particles.forEach((particle, i) => {
      let { t, factor, speed, xFactor, yFactor, zFactor } = particle;
      t = particle.t += speed / 2;
      const a = Math.cos(t) + Math.sin(t * 1) / 10;
      const b = Math.sin(t) + Math.cos(t * 2) / 10;
      const s = Math.max(1.5, Math.cos(t) * 5);
      particle.mx += (mouse.current[0] - particle.mx) * 0.02;
      particle.my += (-mouse.current[1] - particle.my) * 0.02;
      dummy.position.set(
        (particle.mx / 10) * a +
          xFactor +
          Math.cos((t / 10) * factor) +
          (Math.sin(t * 1) * factor) / 10,
        (particle.my / 10) * b +
          yFactor +
          Math.sin((t / 10) * factor) +
          (Math.cos(t * 2) * factor) / 10,
        (particle.my / 10) * b +
          zFactor +
          Math.cos((t / 10) * factor) +
          (Math.sin(t * 3) * factor) / 10
      );
      dummy.scale.set(s, s, s);
      dummy.updateMatrix();
      mesh.current.setMatrixAt(i, dummy.matrix);
    });
    mesh.current.instanceMatrix.needsUpdate = true;
  });
  const ballColor = useControl("ballColor", { type: "color", value: "#ff0" });

  return (
    <>
      <instancedMesh ref={mesh} args={[null, null, count]}>
        <sphereBufferGeometry attach="geometry" args={[1, 32, 32]} />
        <meshPhongMaterial attach="material" color={ballColor} />
      </instancedMesh>
    </>
  );
}

function ResponsiveText() {
  //const { viewport } = useThree();
  const color = useControl("textColor", { type: "color", value: "#000" });
  const fontSize = useControl("fontSize", {
    type: "number",
    value: 16.5,
    min: 1,
    max: 100,
  });
  const maxWidth = useControl("maxWidth", {
    type: "number",
    value: 90,
    min: 1,
    max: 100,
  });
  const lineHeight = useControl("lineHeight", {
    type: "number",
    value: 0.75,
    min: 0.1,
    max: 10,
  });
  const letterSpacing = useControl("spacing", {
    type: "number",
    value: -0.08,
    min: -0.5,
    max: 1,
  });
  const textAlign = useControl("textAlign", {
    type: "select",
    items: ["left", "right", "center", "justify"],
    value: "justify",
  });
  return (
    <Text
      color={color}
      fontSize={fontSize}
      maxWidth={maxWidth}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      textAlign={textAlign}
      font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
      anchorX="center"
      anchorY="middle"
    >
      Magik Studio
    </Text>
  );
}

function App() {
  const primaryLightColor = useControl("primaryLightColor", {
    type: "color",
    value: "#fff",
  });

  const secondaryLightColor = useControl("secondaryLightColor", {
    type: "color",
    value: "#ff0",
  });

  const mouse = useRef([0, 0]);
  const onMouseMove = useCallback(
    ({ clientX: x, clientY: y }) =>
      (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]),
    []
  );

  return (
    <div style={{ width: "100%", height: "100%" }} onMouseMove={onMouseMove}>
      <Canvas
        pixelRatio={window.devicePixelRatio}
        gl={{ alpha: false, antialias: false, logarithmicDepthBuffer: true }}
        camera={{ fov: 75, position: [0, 0, 70] }}
        onCreated={({ gl }) => {
          gl.setClearColor("white");
          gl.toneMapping = THREE.ACESFilmicToneMapping;
          gl.outputEncoding = THREE.sRGBEncoding;
        }}
      >
        <ambientLight intensity={1.1} />
        <pointLight
          position={[100, 100, 100]}
          intensity={2.2}
          color={primaryLightColor}
        />
        <pointLight
          position={[-100, -100, -100]}
          intensity={5}
          color={secondaryLightColor}
        />
        {/* <ResponsiveText /> */}
        <Swarm mouse={mouse} count={100} />
        <Suspense fallback={null}>
          <Effects />
        </Suspense>
      </Canvas>
      <a href="#about" class="top-left" children="About" />
      <a
        href="mailto:hello@magik.studio"
        class="top-right"
        children="Contact us"
      />
      <span class="header">Magik Studio</span>

      {/* <Controls /> */}
    </div>
  );

  // return (
  //   <>
  //     <Canvas
  //       colorManagement
  //       pixelRatio={window.devicePixelRatio}
  //       camera={{ position: [0, 0, 200] }}
  //     >
  //       <ambientLight />
  //       <pointLight position={[10, 10, 10]} />
  //       <ResponsiveText />

  //       <Stars saturation={0.1} />
  //       <TrackballControls />
  //     </Canvas>{" "}
  //     <Controls />
  //   </>
  // );
}

export default App;
